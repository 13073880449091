import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contacts from "../pages/contacts";
import EditContact from "../pages/contacts/edit";
import NewContact from "../pages/contacts/new";
import Home from "../pages/home";
import Login from "../pages/login";
import Register from "../pages/register";
import PrivateRoute from "./private-route";
import Construction from "../pages/construction";
import Instances from "../pages/whatsapp/instances";
import NewInstance from "../pages/whatsapp/instances/new-instance";
import DetailsInstances from "../pages/whatsapp/instances/details";
import Profile from "../pages/profile";
import Dashboard from "../pages/whatsapp";
import ForgotPassword from "../pages/forgot-password";
import Checkout from "../pages/checkout";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-password/:token" element={<ForgotPassword />} />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />

        <Route
          path="/contacts"
          element={
            <PrivateRoute>
              <Contacts />
            </PrivateRoute>
          }
        />
        <Route
          path="/contacts/new"
          element={
            <PrivateRoute>
              <NewContact />
            </PrivateRoute>
          }
        />
        <Route
          path="/contacts/:contactId/edit"
          element={
            <PrivateRoute>
              <EditContact />
            </PrivateRoute>
          }
        />

        <Route
          path="/construction"
          element={
            <PrivateRoute>
              <Construction />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        <Route
          path="/whatsapp"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/whatsapp/instances"
          element={
            <PrivateRoute>
              <Instances />
            </PrivateRoute>
          }
        />

        <Route
          path="/whatsapp/instances/register"
          element={
            <PrivateRoute>
              <NewInstance />
            </PrivateRoute>
          }
        />

        <Route
          path="/whatsapp/instances/details/:instanceId"
          element={
            <PrivateRoute>
              <DetailsInstances />
            </PrivateRoute>
          }
        />

        <Route
          path="/whatsapp/instances/:instanceId/checkout"
          element={
            <PrivateRoute>
              <Checkout />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}
