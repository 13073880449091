import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import api from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import apiErrorHandler from "../../../services/api-error-handler";
import Input from "../../../components/inputs/input";
import CleanLayout from "../../../components/layouts/clean";
import { colors } from "../../../style/colors";
import Button from "../../../components/button";

interface ForgotPasswordForm {
  user: string;
}

export default function SendCode() {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordForm>({
    values: { user: "" },
  });

  const handleForgotPassword = async (data: ForgotPasswordForm) => {
    setLoading(true);
    api
      .post<ServiceResult>("/reset-password", data)
      .then(() => {
        toast.success("E-mail enviado com sucesso!");
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  };

  return (
    <CleanLayout
      renderPosition="left"
      title="Recuperar acesso IATools"
      image="/images/send-email-icon.png"
    >
      <h1>Redefinir senha</h1>
      <p className="text-500-darkest-16 m-0 mb-1">
        Informe seu e-mail cadastrado para enviar um link de redefinição de
        senha.
      </p>
      <form
        className="text-500-darkest-16 w-100 mt-md-5 d-flex flex-column justify-content-center"
        onSubmit={handleSubmit(handleForgotPassword)}
      >
        <p className="text-400-black-18 m-0 mb-1">E-mail do usuário</p>
        <div className="form-group mb-4">
          <Input
            control={control}
            errors={errors}
            type="user"
            name="user"
            validation={{ required: "E-mail é obrigatório" }}
            placeholder="example@email.com"
            height="40px"
          />
        </div>

        <div className="mt-4">
          <Button
            text={loading ? "Enviando..." : "Enviar e-mail"}
            color={colors.gradient.secondary}
            disabled={loading}
          />
        </div>

        <div className="mt-3 text-center">
          <Link
            to="/login"
            className="text-500-darkest-16"
            style={{ color: colors.secondary }}
          >
            Voltar para o login
          </Link>
        </div>
      </form>
    </CleanLayout>
  );
}
