import MainLayout from "../../components/layouts/main";
import { CardsInstances } from "../../components/card-instances/cards-instances";

export default function Home() {
  return (
    <MainLayout>
      <CardsInstances />
    </MainLayout>
  );
}
