import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from "react-router-dom";
import CleanLayout from "../../../components/layouts/clean";
import Input from "../../../components/inputs/input";
import { colors } from "../../../style/colors";
import Button from "../../../components/button";
import PasswordResetPost from "../../../interfaces/args/password-reset-post.args";
import ServiceResult from "../../../interfaces/service-result";
import toast from "react-hot-toast";
import apiErrorHandler from "../../../services/api-error-handler";
import api from "../../../services/api-client";

interface FormValues {
  newPassword: string;
  confirmPassword: string;
}

export default function NewPassword() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormValues>();

  const onSubmit = (data: FormValues) => {
    setLoading(true);

    const args: PasswordResetPost = {
      newPassword: data.newPassword,
      passwordResetToken: token ?? "",
    };

    api
      .post<ServiceResult>(`/reset-password`, args)
      .then(() => {
        toast.success("Senha redefinida com sucesso!");
        navigate("/login");
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  };

  return (
    <CleanLayout
      renderPosition="right"
      title="Recuperar acesso IATools"
      image="/images/new-password-icon.png"
    >
      <h1>Defina sua nova senha</h1>

      <form
        className="text-500-darkest-16 w-100 mt-3 mt-md-5 d-flex flex-column justify-content-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="text-400-black-18 m-0 mb-1">Nova senha</p>
        <div className="form-group mb-4">
          <Input
            control={control}
            errors={errors}
            validation={{
              required: "Senha é obrigatória",
            }}
            placeholder="nova senha"
            height="40px"
            type="password"
            name="newPassword"
          />
        </div>

        <p className="text-400-black-18 m-0 mb-1">Confirme sua nova senha</p>
        <div className="form-group mb-4">
          <Input
            control={control}
            errors={errors}
            validation={{
              required: "Confirmação de senha é obrigatória",
              validate: (value) =>
                value === watch("newPassword") || "As senhas não coincidem",
            }}
            placeholder="confirme sua nova senha"
            height="40px"
            type="password"
            name="confirmPassword"
          />
        </div>

        <div className="mt-4">
          <Button
            text={loading ? "Redefinindo..." : "Redefinir senha"}
            color={colors.gradient.secondary}
            disabled={loading}
          />
        </div>

        <div className="mt-3 text-center">
          <Link
            to="/login"
            className="text-500-darkest-16"
            style={{ color: colors.secondary }}
          >
            Voltar para o login
          </Link>
        </div>
      </form>
    </CleanLayout>
  );
}
