import clsx from "clsx";
import ReactInputMask from "react-input-mask";
import "./input.css";

import { ComponentProps, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface Props extends Omit<ComponentProps<"input">, "ref"> {
  control: Control<any>;
  errors: FieldErrors;
  height?: string;
  name: string;
  mask?: string;
  maskChar?: string;
  type?: React.HTMLInputTypeAttribute;
  validation: RegisterOptions;
}

const Input: React.FC<Props> = ({
  className = "",
  control,
  errors,
  height = "",
  name,
  mask = "",
  maskChar = "",
  type = "text",
  validation,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field }) => (
        <div className="password-input-container w-100">
          <ReactInputMask
            id={field.name}
            name={field.name}
            mask={mask}
            maskChar={maskChar}
            type={
              type === "password" ? (showPassword ? "text" : "password") : type
            }
            className={clsx(
              `${className} form-control`,
              errors[name] && "border border-danger text-danger",
            )}
            onChange={field.onChange}
            style={{
              height: height,
            }}
            value={field.value}
            {...rest}
          />
          {type === "password" && (
            <span
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default Input;
