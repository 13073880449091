import { useEffect, useState } from "react";
import { FiHardDrive } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline, IoMdPower } from "react-icons/io";
import { Link } from "react-router-dom";
import ConnectionsWhatsAppInstancesSummaryModel from "../../interfaces/models/connections-instances-summary.model";
import api from "../../services/api-client";
import ServiceResult from "../../interfaces/service-result";
import apiErrorHandler from "../../services/api-error-handler";

export const CardsInstances = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [instances, setInstances] =
    useState<ConnectionsWhatsAppInstancesSummaryModel | null>(null);

  async function getSummary(): Promise<void> {
    setLoading(true);

    api
      .get<ServiceResult<ConnectionsWhatsAppInstancesSummaryModel>>(
        "/connections/whatsapp/instances/summary"
      )
      .then(({ data }) => {
        setInstances(data.result as ConnectionsWhatsAppInstancesSummaryModel);
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getSummary();
  }, []);

  return (
    <div className="row">
      <div className="col-sm-12 col-xl-6 col-xxl-4 mb-4">
        <div className="bg-white p-3 shadow rounded-2 d-flex h-100">
          {/* Container Flex para os dois elementos internos */}
          <div className="d-flex w-100">
            <Link
              to="/whatsapp/instances"
              className="d-flex align-items-center justify-content-center rounded-2"
              style={{
                background: "#87CEFF",
                width: "70px",
                height: "70px",
              }}
            >
              <FiHardDrive size={30} color="#36648B" />
            </Link>
            <div className="d-flex flex-column mx-3 flex-grow-1">
              <span className="d-block fs-6 fw-semibold">Conexões</span>
              {loading ? (
                <span
                  className="placeholder bg-secondary mb-1 mt-1"
                  style={{
                    width: "70px",
                    height: "10px",
                    background: "#858796",
                  }}
                ></span>
              ) : (
                <span className="fs-5 fw-bold">
                  {instances?.connections || 0}
                </span>
              )}

              <div
                className="d-flex justify-content-between"
                style={{ fontSize: "12px" }}
              >
                <span>Total de conexões registradas</span>
                <Link to="/whatsapp/instances" className="text-black">
                  Ver {">"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-xl-6 col-xxl-4 mb-4">
        <div className="bg-white p-3 shadow rounded-2 d-flex h-100">
          <div className="d-flex w-100">
            <Link
              to="/whatsapp/instances?tab=conectadas"
              className="d-flex align-items-center justify-content-center rounded-2"
              style={{ background: "#9CFF9C", width: "70px", height: "70px" }}
            >
              <IoMdCheckmarkCircleOutline size={30} color="#008B45" />
            </Link>
            <div className="d-flex flex-column mx-3 flex-grow-1">
              <span className="d-block fs-6 fw-semibold">Conexões ativas</span>
              {loading ? (
                <span
                  className="placeholder bg-secondary mb-1 mt-1"
                  style={{
                    width: "70px",
                    height: "10px",
                    background: "#858796",
                  }}
                ></span>
              ) : (
                <span className="fs-5 fw-bold">
                  {instances?.connected || 0}
                </span>
              )}
              <div
                className="d-flex justify-content-between"
                style={{ fontSize: "12px" }}
              >
                <span>Total de conexões ativas</span>
                <Link
                  to="/whatsapp/instances?tab=conectadas"
                  className="text-black"
                >
                  Ver {">"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-xl-6 col-xxl-4 mb-4">
        <div className="bg-white p-3 shadow rounded-2 d-flex h-100">
          <div className="d-flex w-100">
            <Link
              to="/whatsapp/instances?tab=desconectadas"
              className="d-flex align-items-center justify-content-center rounded-2"
              style={{ background: "#FFB6C1", width: "70px", height: "70px" }}
            >
              <IoMdPower size={30} color="#FF4040" />
            </Link>
            <div className="d-flex flex-column mx-3 flex-grow-1">
              <span className="d-block fs-6 fw-semibold">
                Conexões inativas
              </span>
              {loading ? (
                <span
                  className="placeholder bg-secondary mb-1 mt-1"
                  style={{
                    width: "70px",
                    height: "10px",
                    background: "#858796",
                  }}
                ></span>
              ) : (
                <span className="fs-5 fw-bold">
                  {instances?.disconnected || 0}
                </span>
              )}
              <div
                className="d-flex justify-content-between"
                style={{ fontSize: "12px" }}
              >
                <span>Total de conexões inativas</span>
                <Link
                  to="/whatsapp/instances?tab=desconectadas"
                  className="text-black"
                >
                  Ver {">"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
