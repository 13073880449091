import { create } from "zustand";
import MeModel from "../interfaces/models/me.model";

type profileStore = {
  profileData: MeModel;
  setProfileData: (value: MeModel) => void;
};

export const useProfileStore = create<profileStore>((set) => ({
  profileData: {} as MeModel,
  setProfileData: (value) => set({ profileData: value }),
}));
