import { useSearchParams } from "react-router-dom";
import CleanLayout from "../../components/layouts/clean";
import { RegisterForm } from "./components/register-form";

export default function Register() {
  const [searchParams, setSearchParams] = useSearchParams();

  const validating = !!searchParams.get("confirmation");

  return (
    <>
      <RegisterForm />
    </>
  );
}
