import MainLayout from "../../../../components/layouts/main";
import { FiCopy } from "react-icons/fi";
import { MdArrowBack } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import ConnectionWhatsAppInstanceModel from "../../../../interfaces/models/connection-whatsapp-instance.model";
import api from "../../../../services/api-client";
import { useNavigate, useParams } from "react-router-dom";
import ServiceResult from "../../../../interfaces/service-result";
import apiErrorHandler from "../../../../services/api-error-handler";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";
import WhatsAppConnectionStatus from "../../../../enums/whatsapp-connection-status";
import PaymentStatus from "../../../../enums/payment-status";
import moment from "moment";
import { ConvertPaymentStatusToString } from "../../../../utils/convert-payment-status-to-string";
import axios, { CancelTokenSource } from "axios";

import background from "../../../../images/qr_code.png";
const COUNT_DOWN_IN_MS = 3e3;

export default function DetailsInstances() {
  const { instanceId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDisconnected, setLoadingDisconnected] =
    useState<boolean>(false);
  const [loadingConnect, setLoadingConnect] = useState<boolean>(false);
  const [instance, setInstance] = useState<ConnectionWhatsAppInstanceModel>(
    {} as ConnectionWhatsAppInstanceModel
  );
  const [qrCode, setQrCode] = useState<string | null>(null);

  const isRequestInProgress = useRef<boolean>(false); // Controle de concorrência
  const checkCancelToken = useRef<CancelTokenSource | null>(null);
  const requestQueue = useRef<(() => Promise<void>)[]>([]); // Fila de requisições

  function executeNextRequest() {
    if (requestQueue.current.length > 0) {
      const nextRequest = requestQueue.current.shift();
      nextRequest && nextRequest(); // Executa a próxima requisição na fila
    }
  }

  function enqueueRequest(requestFunc: () => Promise<void>) {
    if (isRequestInProgress.current) {
      requestQueue.current.push(requestFunc); // Enfileira a requisição
    } else {
      requestFunc(); // Executa a requisição imediatamente se não houver outra em andamento
    }
  }

  function handleCopy(
    textToCopy: string,
    field:
      | "Número do WhatsApp"
      | "Domínio da API"
      | "Chave da API"
      | "Nome da Instância"
  ) {
    navigator?.clipboard?.writeText(textToCopy);
    toast.success(`${field} copiado para a área de transferência!`);
  }

  async function fetchInstance(): Promise<void> {
    setLoading(true);

    api
      .get<ServiceResult<ConnectionWhatsAppInstanceModel>>(
        `/connections/whatsapp/instances/${instanceId}`
      )
      .then(({ data }) => {
        setInstance(data.result as ConnectionWhatsAppInstanceModel);
        if (
          data.result?.status ===
            WhatsAppConnectionStatus.WaitingForConnection &&
          !qrCode
        ) {
          connectAndFetchQrCode();
        }
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  }

  async function connectAndFetchQrCode(): Promise<void> {
    enqueueRequest(async () => {
      if (isRequestInProgress.current) return; // Ignorar se já houver uma requisição em andamento

      setLoadingConnect(true);

      isRequestInProgress.current = true;

      api
        .patch<ServiceResult<string>>(
          `/connections/whatsapp/instances/${instanceId}/connect`
        )
        .then(({ data }) => {
          setQrCode(data.result as string);
          setInstance((prev) => ({
            ...prev,
            status: WhatsAppConnectionStatus.WaitingForConnection,
          }));
        })
        .catch(apiErrorHandler)
        .finally(() => {
          isRequestInProgress.current = false;
          setLoadingConnect(false);
          executeNextRequest(); // Executa a próxima requisição na fila, se houver
        });
    });
  }

  async function disconnect(): Promise<void> {
    enqueueRequest(async () => {
      if (isRequestInProgress.current) return; // Ignorar se já houver uma requisição

      setLoadingDisconnected(true);

      isRequestInProgress.current = true;

      api
        .patch<ServiceResult>(
          `/connections/whatsapp/instances/${instanceId}/disconnect`
        )
        .then(() => {
          setQrCode(null);
          toast("Instância desconectada");
          setInstance((prev) => ({
            ...prev,
            status: WhatsAppConnectionStatus.Disconnected,
          }));
          enqueueRequest(fetchInstance); // Atualiza os dados da instância
        })
        .catch(apiErrorHandler)
        .finally(() => {
          isRequestInProgress.current = false;
          setLoadingDisconnected(false);
          executeNextRequest(); // Executa a próxima requisição na fila, se houver
        });
    });
  }

  async function connectioncheck(): Promise<void> {
    enqueueRequest(async () => {
      if (isRequestInProgress.current) return; // Ignorar se já houver uma requisição

      setLoadingConnect(true);

      isRequestInProgress.current = true;
      checkCancelToken.current = axios.CancelToken.source();

      api
        .get<ServiceResult<{ status: WhatsAppConnectionStatus }>>(
          `/connections/whatsapp/instances/${instanceId}/connection/check`,
          {
            cancelToken: checkCancelToken.current.token,
          }
        )
        .then(({ data }) => {
          setInstance((prev) => ({
            ...prev,
            status: data.result!.status,
          }));
          if (
            data.result?.status !==
            WhatsAppConnectionStatus.WaitingForConnection
          ) {
            setQrCode(null);
            enqueueRequest(fetchInstance); // Atualiza os dados da instância
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.warn("Requisição cancelada:", error.message);
          } else {
            apiErrorHandler(error);
            setInstance((prev) => ({
              ...prev,
              status: WhatsAppConnectionStatus.Disconnected,
            }));
            setQrCode(null);
          }
        })
        .finally(() => {
          isRequestInProgress.current = false;
          // checkCancelToken.current = null; // ????
          setLoadingConnect(false);
          executeNextRequest(); // Executa a próxima requisição na fila, se houver
        });
    });
  }

  const buttonOrange =
    instance.dueDate && moment(instance.dueDate).diff(moment(), "days") <= 7;

  useEffect(() => {
    fetchInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (
      instance.status === WhatsAppConnectionStatus.WaitingForConnection &&
      qrCode
    ) {
      intervalId = setInterval(() => {
        connectioncheck();
      }, COUNT_DOWN_IN_MS);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }

      if (checkCancelToken.current) {
        checkCancelToken.current.cancel(
          "Polling cancelled due to component unmount or new request"
        );
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCode, instance.status]);

  return (
    <MainLayout>
      <div className="bg-white shadow p-4">
        <div
          className="d-flex align-items-center cursor-pointer mb-4"
          onClick={() => navigate(-1)}
        >
          <MdArrowBack size={20} />
          <span className="semibold-16 mx-2">Voltar</span>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0 bold-20">
                1. Dados da instância: {instance.name}
              </p>
            </div>
            <p className="mt-3 mb-0 semibold-14 text-secondary">
              Essas são suas informações para integração com esta instância, não
              compartilhe essas informações.
            </p>

            <div className="row">
              <div className="col-lg-6">
                <label className="d-flex align-items-center mt-3 semibold-14 text-secondary">
                  <span>Número do WhatsApp</span>
                  <FiCopy
                    className="mx-3 cursor-pointer"
                    onClick={() =>
                      handleCopy(instance.number || "", "Número do WhatsApp")
                    }
                  />
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder={loading ? "..." : "Número do WhatsApp"}
                  id="number"
                  name="number"
                  value={instance.number || ""}
                  disabled
                />
              </div>

              <div className="col-lg-6">
                <label className="d-flex align-items-center mt-3 semibold-14 text-secondary">
                  <span>Domínio da API</span>
                  <FiCopy
                    className="mx-3 cursor-pointer"
                    onClick={() =>
                      handleCopy(
                        instance.serverBaseAddress || "",
                        "Domínio da API"
                      )
                    }
                  />
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder={loading ? "..." : "Domínio da API"}
                  id="serverBaseAddress"
                  name="serverBaseAddress"
                  value={instance.serverBaseAddress}
                  disabled
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-6">
                <label className="d-flex align-items-center mt-3 semibold-14 text-secondary">
                  <span>Chave da API</span>
                  <FiCopy
                    className="mx-3 cursor-pointer"
                    onClick={() =>
                      handleCopy(instance.instanceKey || "", "Chave da API")
                    }
                  />
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder={loading ? "..." : "Chave da API"}
                  id="instanceKey"
                  name="instanceKey"
                  value={instance.instanceKey}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <label className="d-flex align-items-center mt-3 semibold-14 text-secondary">
                  <span>Nome da instância</span>
                  <FiCopy
                    className="mx-3 cursor-pointer"
                    onClick={() =>
                      handleCopy(
                        instance.instanceName || "",
                        "Nome da Instância"
                      )
                    }
                  />
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  placeholder={loading ? "..." : "Token da instância"}
                  id="instanceName"
                  name="instanceName"
                  value={instance.instanceName}
                  disabled
                />
              </div>
            </div>

            <p className="mt-4 bold-20">Assinatura</p>
            <p className="fw-bold text-secondary mb-0">
              Status atual:{" "}
              {loading ? (
                <span
                  className="placeholder bg-secondary mb-1 mt-1"
                  style={{
                    width: "100px",
                    height: "10px",
                    background: "#858796",
                  }}
                ></span>
              ) : (
                <span
                  className={`${
                    instance.paymentStatus === PaymentStatus.Paid
                      ? "text-success"
                      : "text-danger"
                  } text-uppercase`}
                >
                  {ConvertPaymentStatusToString(instance.paymentStatus)}
                </span>
              )}
            </p>
            {instance.dueDate && (
              <p className="fw-bold text-secondary mb-0 mt-2">
                Próximo vencimento{" "}
                {loading ? (
                  <span
                    className="placeholder bg-secondary mb-1 mt-1"
                    style={{
                      width: "100px",
                      height: "10px",
                      background: "#858796",
                    }}
                  ></span>
                ) : (
                  <span className="fw-normal mx-1">
                    {moment(instance.dueDate).format("DD/MM/YYYY HH:mm")}
                  </span>
                )}
              </p>
            )}

            <button
              className={`${
                buttonOrange
                  ? "btn-warning"
                  : instance.paymentStatus === PaymentStatus.Overdue
                  ? "btn-danger"
                  : "btn-light"
              } btn mt-2 mb-2`}
              onClick={() =>
                navigate(`/whatsapp/instances/${instance.id}/checkout`)
              }
            >
              Renovar
            </button>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0 d-flex flex-column justify-content-center align-items-center">
            {instance.status === WhatsAppConnectionStatus.Connected && (
              <>
                <p className="mb-0 fw-semibold fs-4 text-success text-center">
                  Conectado
                </p>
                <p className="mt-2 text-center semibold-14 text-secondary">
                  Sua instância está conectada, você ja pode enviar mensagens
                  via API
                </p>

                <div className="d-flex align-items-center justify-content-center mt-4">
                  <img
                    src="/images/icon-correct.png"
                    alt="icon-correct"
                    style={{ width: "120px", height: "120px" }}
                  />
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                  <button
                    className="btn btn-danger rounded-pill px-5 py-2 fw-semibold"
                    style={{ width: "220px" }}
                    onClick={disconnect}
                    disabled={loadingDisconnected}
                  >
                    {loadingDisconnected ? "Desconectando..." : "Desconectar"}
                  </button>
                </div>
              </>
            )}

            {instance.status ===
              WhatsAppConnectionStatus.WaitingForConnection &&
              qrCode && (
                <>
                  <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                    <p className="mb-0 fw-semibold fs-4 text-warning text-center">
                      Sua instância está aguardando conexão
                    </p>
                    <p className="mt-2 text-center semibold-14 text-secondary">
                      Escaneie o QRCode para conectar
                    </p>

                    <QRCode
                      className="pt-4 pt-lg-0"
                      style={{
                        height: "100%",
                        maxWidth: "220px",
                        width: "100%",
                      }}
                      viewBox="0 0 256 256"
                      value={qrCode}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                    <button
                      className="btn btn-primary rounded-pill px-5 py-2 fw-semibold"
                      onClick={connectAndFetchQrCode}
                    >
                      Renovar QRCode
                    </button>
                  </div>
                </>
              )}

            {instance.status === WhatsAppConnectionStatus.Disconnected && (
              <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                <p className="mb-0 fw-semibold fs-4 text-danger text-center">
                  Instância desconectada
                </p>
                <p className="mt-2 text-center semibold-14 text-secondary">
                  Conecte-se com o WhatsApp clicando no botão abaixo para gerar
                  um QRCode
                </p>
                <div className="position-relative">
                  <img
                    src={background}
                    alt="background"
                    className="w-100"
                    width={256}
                    height={256}
                    style={{ filter: "blur(5px)" }}
                  />
                  <button
                    className="btn btn-primary rounded-pill px-5 py-2 fw-semibold position-absolute"
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    onClick={connectAndFetchQrCode}
                    disabled={loadingConnect}
                  >
                    {loadingConnect ? "Conectando..." : "Conectar"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
