import { FaBars } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { NavLinks } from "./components/nav-links";
import { MdLogout } from "react-icons/md";

export default function Sidebar() {
  const navigate = useNavigate();

  return (
    <nav className="nav flex-column sidebar" style={{ overflowX: "hidden" }}>
      {/* Sidebar Web */}
      <div className="p-4">
        <div className="d-flex align-items-center justify-content-between">
          <Link
            to="/"
            className="h2 text-decoration-none text-white d-flex align-items-center"
          >
            <img src="/images/logo.svg" alt="logo" height={40} width={40} />
            <span className="ms-2">IATools</span>
          </Link>
          <button
            className="d-block d-md-none bg-transparent border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#staticBackdrop"
            aria-controls="staticBackdrop"
          >
            <FaBars className="text-white fs-3" />
          </button>
        </div>

        <hr className="text-white d-none d-md-block" />

        <div className="d-none d-md-flex flex-column justify-content-between">
          <NavLinks />
          {/*
          <div className="d-flex flex-column">
            <hr className="text-white w-100" />
            <Link
              to="/ajuda"
              className="text-decoration-none text-white w-100 d-flex align-items-center mb-3"
            >
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ width: "40px" }}
              >
                <FaQuestionCircle />
              </div>
              <span className="text-white medium-14">Precisa de ajuda?</span>
            </Link>
          </div>
          */}
        </div>

        {/* Sidebar Mobile */}
        <div
          className="offcanvas offcanvas-start bg-sidebar"
          data-bs-backdrop="static"
          tabIndex={-1}
          id="staticBackdrop"
          aria-labelledby="staticBackdropLabel"
        >
          <div className="offcanvas-header mx-2 mt-2 pb-0">
            <Link
              to="/"
              className="h2 text-decoration-none text-white d-flex align-items-center mb-0"
            >
              <img src="/images/logo.svg" alt="logo" height={40} width={40} />
              <span className="ms-2">IATools</span>
            </Link>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body d-flex flex-column">
            <hr className="text-white" />

            <NavLinks />
            {/*
              <div className="d-flex flex-column">
                <hr className="text-white w-100" />
                <Link
                  to="/ajuda"
                  className="text-decoration-none text-white w-100 d-flex align-items-center mb-3"
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ width: "40px" }}
                  >
                    <FaQuestionCircle />
                  </div>
                  <span className="text-white medium-14">
                    Precisa de ajuda?
                  </span>
                </Link>
              </div>
              */}

            <div className="mt-auto">
              <div
                className="text-white w-100 d-flex align-items-center mb-3 cursor-pointer"
                onClick={() => {
                  sessionStorage.clear();
                  navigate("/login");
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  style={{ width: "40px" }}
                >
                  <MdLogout size={24} />
                </div>
                <span className="text-white medium-16">Sair</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
