export type Colors = {
  primary: string;
  secondary: string;
  tertiary: string;
  gradient: {
    primary: string;
    secondary: string;
  };
};

export const colors: Colors = {
  primary: "#2B65D6",
  secondary: "#00A6EB",
  tertiary: "#F8A959",
  gradient: {
    primary: "linear-gradient(black, #2b65d6)",
    secondary: "linear-gradient(to right, black, #2b65d6)",
  },
};
