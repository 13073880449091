import { useEffect, useState } from "react";
import MainLayout from "../../../components/layouts/main";
import { CardsInstances } from "../../../components/card-instances/cards-instances";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { IoEyeOutline } from "react-icons/io5";
import api from "../../../services/api-client";
import ListServiceResult from "../../../interfaces/list-service-result";
import apiErrorHandler from "../../../services/api-error-handler";
import { Loading } from "../../../components/loading";
import { SearchFilter } from "../../../components/search-filter";
import { TablePagination } from "../../../components/table-pagination";
import { BuildRelativeUrl } from "../../../utils/build-relative-url";
import ConnectionWhatsAppInstanceListItemModel from "../../../interfaces/models/connection-whatsapp-instance-list-item.model";
import moment from "moment";
import WhatsAppConnectionStatus from "../../../enums/whatsapp-connection-status";
import { ConvertStatusToString } from "../../../utils/convert-status-to-string";
import { ConvertPaymentStatusToString } from "../../../utils/convert-payment-status-to-string";

export default function Instances() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.get("searchString") || "";
  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("pageSize")) || 10;

  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [instances, setInstances] = useState<
    ConnectionWhatsAppInstanceListItemModel[]
  >([]);
  const [tabsPage, setTabsPage] = useState<{ name: string; active: boolean }[]>(
    [
      { name: "Todas", active: true },
      { name: "Conectadas", active: false },
      { name: "Desconectadas", active: false },
    ]
  );

  const updateTabsAndURL = (tab?: string) => {
    const tabName = tab || searchParams.get("tab") || "todas";
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev.toString());
      if (tabName === "todas") {
        newParams.delete("tab");
      } else {
        newParams.set("tab", tabName.toLowerCase());
      }
      return newParams;
    });

    setTabsPage((prev) =>
      prev.map((item) => ({
        ...item,
        active: item.name.toLowerCase() === tabName.toLowerCase(),
      }))
    );
  };

  const filteredInstances = instances.filter((instance) => {
    const selectedTab = tabsPage.find((tab) => tab.active)?.name;
    if (selectedTab === "Conectadas") {
      return instance.status === WhatsAppConnectionStatus.Connected;
    } else if (selectedTab === "Desconectadas") {
      return instance.status === WhatsAppConnectionStatus.Disconnected;
    } else {
      return true;
    }
  });

  async function getInstances(): Promise<void> {
    setLoading(true);

    api
      .get<ListServiceResult<ConnectionWhatsAppInstanceListItemModel>>(
        BuildRelativeUrl("/connections/whatsapp/instances", {
          searchString: searchString,
          page,
          pageSize,
        })
      )
      .then(({ data }) => {
        setInstances(data.result);
        setNumeroPaginas(data.pages);
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    updateTabsAndURL();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    getInstances();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, page, pageSize]);

  return (
    <MainLayout>
      <CardsInstances />

      <div className="row mt-2">
        <div className="col-lg-7">
          <div className="d-flex flex-column flex-xl-row justify-content-center justify-content-xl-between align-items-center">
            <div className="d-flex flex-wrap align-items-center justify-content-center mb-3">
              {tabsPage.map((tab, i) => (
                <button
                  key={i}
                  className={`btn-tab bg-transparent me-3 mb-2 ${
                    tab.active && "btn-tab-active"
                  }`}
                  onClick={() => updateTabsAndURL(tab.name.toLowerCase())}
                >
                  <p className="m-0 fw-semibold">{tab.name}</p>
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="col-lg-5">
          <div className="d-flex align-items-center justify-content-end">
            <SearchFilter />

            <Link
              to="/whatsapp/instances/register"
              className="btn btn-success fw-semibold ms-2"
            >
              <div className="d-flex align-items-center">
                <IoMdAdd size={18} />
                <span className="mx-1">Adicionar</span>
              </div>
            </Link>

            {/* <BsThreeDotsVertical size={40} className="ms-2" /> */}
          </div>
        </div>
      </div>

      {loading ? (
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Loading />
        </div>
      ) : filteredInstances.length === 0 || instances.length === 0 ? (
        <p className="d-flex align-items-center justify-content-center text-center text-secondary mt-3 semibold-16">
          Sem conexões no momento
        </p>
      ) : (
        <>
          <div className="table-responsive mt-3">
            <table className="table">
              <thead>
                <tr>
                  <th className="fw-semibold">Nome</th>
                  <th className="fw-semibold">Número</th>
                  <th className="fw-semibold">Status</th>
                  <th className="fw-semibold">Vencimento</th>
                  <th className="fw-semibold">Pagamento</th>
                  <th className="fw-semibold text-center">Visualizar</th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {filteredInstances.map((instance) => (
                  <tr key={instance.id}>
                    <td>{instance.name}</td>
                    {/* <td>
                        <div className="d-flex align-items-center justify-content-between">
                          <span>S11DA1611998</span>
                          <FiCopy className="cursor-pointer" />
                        </div>
                      </td> */}
                    {/* <td>
                        <div className="d-flex align-items-center justify-content-between">
                          <span>11919DADWADAW</span>
                          <FiCopy className="cursor-pointer" />
                        </div>
                      </td> */}
                    <td>{instance.number}</td>
                    {instance.status === WhatsAppConnectionStatus.Connected ? (
                      <td className="text-success">
                        {ConvertStatusToString(instance.status)}
                      </td>
                    ) : (
                      <td className="text-danger">
                        {ConvertStatusToString(instance.status)}
                      </td>
                    )}
                    <td>
                      {" "}
                      {instance.dueDate
                        ? moment(instance.dueDate).format("DD/MM/YYYY")
                        : "-"}
                    </td>
                    <td>
                      {ConvertPaymentStatusToString(instance.paymentStatus)}
                    </td>
                    <td className="text-center">
                      <IoEyeOutline
                        className="cursor-pointer"
                        size={20}
                        onClick={() =>
                          navigate(`/whatsapp/instances/details/${instance.id}`)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <TablePagination numeroPaginas={numeroPaginas} />
        </>
      )}
    </MainLayout>
  );
}
