import clsx from "clsx";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../components/layouts/main";
import ContactsPostArgs from "../../../interfaces/args/contacts-post.args";
import ContactModel from "../../../interfaces/models/contact.model";
import ServiceResult from "../../../interfaces/service-result";
import api from "../../../services/api-client";
import apiErrorHandler from "../../../services/api-error-handler";
import somenteNumeros from "../../../utils/somente-numeros";

export default function NewContact() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactsPostArgs>();

  async function onSubmit(data: ContactsPostArgs): Promise<void> {
    setLoading(true);

    let args: ContactsPostArgs = {
      phone: somenteNumeros(data.phone),
      name: data.name,
      addressCity: data.addressCity,
      addressState: data.addressState,
      addressNumber: data.addressNumber,
      addressStreet: data.addressStreet,
      addressZipCode: data.addressZipCode
        ? somenteNumeros(data.addressZipCode)
        : null,
      addressLocation: null,
      addressComplement: data.addressComplement,
      addressNeighborhood: data.addressNeighborhood,
      alias: data.alias,
      email: data.email,
      fullName: data.fullName,
    };

    api
      .post<ServiceResult<ContactModel>>("/contacts", args)
      .then(({ data: result }) => {
        toast.success("Contato salvo com sucesso");
        navigate("/contacts");
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  return (
    <MainLayout>
      <h1>Novo contato</h1>

      <hr />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row g-4">
          <div className="col-md-6 col-lg-4">
            <h2 className="mb-4">Informações básicas</h2>

            <h3 className="mb-2">Dados pessoais</h3>

            <div className="mb-2">
              <label htmlFor="name">Nome*</label>
              <input
                id="name"
                type="text"
                placeholder="Nome"
                className={clsx("form-control", errors.name && "is-invalid")}
                {...register("name", { required: "Nome é obrigatório" })}
              />
              {errors.name && (
                <span className="text-danger">{errors.name.message}</span>
              )}
            </div>

            <div className="mb-2">
              <label htmlFor="phone">Telefone*</label>
              <ReactInputMask
                mask="+55 (99) 99999-9999"
                id="phone"
                type="text"
                placeholder="Telefone"
                className={clsx("form-control", errors.phone && "is-invalid")}
                {...register("phone", { required: "Telefone é obrigatório" })}
              />
              {errors.phone && (
                <span className="text-danger">{errors.phone.message}</span>
              )}
            </div>

            <div className="mb-2">
              <label htmlFor="alias">Apelido</label>
              <input
                id="alias"
                type="text"
                placeholder="Apelido"
                className="form-control"
                {...register("alias")}
              />
            </div>

            <div className="mb-2">
              <label htmlFor="fullName">Nome completo</label>
              <input
                id="fullName"
                type="text"
                placeholder="Nome completo"
                className="form-control"
                {...register("fullName")}
              />
            </div>

            <div className="mb-2">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                placeholder="Email"
                className="form-control"
                {...register("email")}
              />
            </div>
          </div>

          <div className="col-md-6 col-lg-4 mb-2">
            <h2 className="mb-4">Informações adicionais</h2>

            <h3 className="mb-3">Endereço</h3>
            <div className="mb-2">
              <label htmlFor="addressZipCode">CEP</label>
              <ReactInputMask
                mask="99999-999"
                id="addressZipCode"
                type="text"
                placeholder="CEP"
                className="form-control"
                {...register("addressZipCode")}
              />
            </div>

            <div className="mb-2">
              <label htmlFor="addressStreet">Logradouro</label>
              <input
                id="addressStreet"
                type="text"
                placeholder="Logradouro"
                className="form-control"
                {...register("addressStreet")}
              />
            </div>

            <div className="mb-2">
              <label htmlFor="addressNeighborhood">Bairro</label>
              <input
                id="addressNeighborhood"
                type="text"
                placeholder="Bairro"
                className="form-control"
                {...register("addressNeighborhood")}
              />
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <label htmlFor="addressNumber">Número</label>
                <input
                  id="addressNumber"
                  type="text"
                  placeholder="Número"
                  className="form-control"
                  {...register("addressNumber")}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="addressComplement">Complemento</label>
                <input
                  id="addressComplement"
                  type="text"
                  placeholder="Complemento"
                  className="form-control"
                  {...register("addressComplement")}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <label htmlFor="addressCity">Cidade</label>
                <input
                  id="addressCity"
                  type="text"
                  placeholder="Cidade"
                  className="form-control"
                  {...register("addressCity")}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="addressState">Estado</label>
                <input
                  id="addressState"
                  type="text"
                  placeholder="Estado"
                  className="form-control"
                  {...register("addressState")}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="d-flex justify-content-center justify-content-md-start col-md-6 col-lg-4">
              <button
                type="button"
                className="btn btn-secondary me-3"
                onClick={() => navigate("/contacts")}
              >
                Cancelar
              </button>

              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary"
              >
                {loading ? "Carregando..." : "Salvar"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </MainLayout>
  );
}
