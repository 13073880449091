import { useState } from "react";
import EmptyModal from "../../../components/modals/empty-modal";
import { useForm } from "react-hook-form";
import { TbLock, TbLockAccess, TbLockCheck } from "react-icons/tb";
import ChangePasswordPut from "../../../interfaces/args/change-password.args";
import api from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import toast from "react-hot-toast";
import apiErrorHandler from "../../../services/api-error-handler";
import Input from "../../../components/inputs/input";
import InputGroup from "../../../components/inputs/input-group";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const FormPassword = ({ isOpen, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
  } = useForm<ChangePasswordPut>();

  const changePassword = (data: ChangePasswordPut) => {
    setLoading(true);

    const args: ChangePasswordPut = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      passwordConfirmation: data.passwordConfirmation,
    };

    api
      .post<ServiceResult>("/me/change-password", args)
      .then(() => {
        toast.success("Senha alterada com sucesso!");
        onClose();
        reset();
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    onClose();
    reset();
  };

  return (
    <EmptyModal close={handleCancel} isOpen={isOpen} title="Alterar senha">
      <form onSubmit={handleSubmit(changePassword)}>
        <div className="row mb-3">
          <div className="col-lg-12">
            <label
              htmlFor="currentPassword"
              className="form-label semibold-14 text-secondary"
            >
              Senha atual
            </label>
            <InputGroup Icon={TbLockAccess}>
              <Input
                control={control}
                errors={errors}
                name="currentPassword"
                validation={{ required: "Senha atual é obrigatória" }}
                type="password"
                placeholder="Senha atual"
              />
            </InputGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <label
              htmlFor="newPassword"
              className="form-label semibold-14 text-secondary"
            >
              Nova senha
            </label>
            <InputGroup Icon={TbLock}>
              <Input
                control={control}
                errors={errors}
                name="newPassword"
                validation={{
                  required: "Nova senha é obrigatória",
                  validate: {
                    notSameAsCurrent: (value) =>
                      value !== getValues("currentPassword") ||
                      "A nova senha não pode ser a mesma que a senha atual",
                  },
                }}
                type="password"
                placeholder="Nova senha"
              />
            </InputGroup>
          </div>

          <div className="col-lg-6">
            <label
              htmlFor="passwordConfirmation"
              className="form-label semibold-14 text-secondary"
            >
              Confirmar senha
            </label>
            <InputGroup Icon={TbLockCheck}>
              <Input
                control={control}
                errors={errors}
                name="passwordConfirmation"
                validation={{
                  required: "Confirmar senha é obrigatória",
                  validate: (value) => {
                    if (getValues("newPassword") !== value) {
                      return "As senhas não conferem";
                    }
                  },
                }}
                type="password"
                placeholder="Confirmar senha"
              />
            </InputGroup>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end mt-4">
          <button
            className="btn btn-outline-secondary px-5 py-2 rounded-pill semibold-16"
            onClick={handleCancel}
          >
            Cancelar
          </button>

          <button
            type="submit"
            className="btn btn-success px-5 py-2 rounded-pill semibold-16 ms-3"
            disabled={loading}
          >
            {loading ? "Alterando..." : "Alterar"}
          </button>
        </div>
      </form>
    </EmptyModal>
  );
};
