import { useEffect, useState } from "react";
import MainLayout from "../../components/layouts/main";
import ServiceResult from "../../interfaces/service-result";
import api from "../../services/api-client";
import apiErrorHandler from "../../services/api-error-handler";
import { BiPlusCircle } from "react-icons/bi";
import { FaAt, FaHome, FaPhone } from "react-icons/fa";
import { AiOutlineFile, AiOutlineItalic, AiOutlineUser } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import MeModel from "../../interfaces/models/me.model";
import { FormPassword } from "./components/form-password";
import useViaCep from "../../hooks/useViaCep";
import ViaCepModel from "../../interfaces/models/via-cel-model";
import Input from "../../components/inputs/input";
import InputGroup from "../../components/inputs/input-group";
import somenteNumeros from "../../utils/somente-numeros";
import { Loading } from "../../components/loading";

type ProfilePostArgs = Pick<
  MeModel,
  | "fullName"
  | "nationalDocument"
  | "addressZipCode"
  | "addressStreet"
  | "addressNumber"
  | "addressNeighborhood"
  | "addressComplement"
>;

export default function ProfileZapi() {
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [initialProfileData, setInitialProfileData] = useState<MeModel | null>(
    null
  );

  const [openModal, setOpenModal] = useState<boolean>(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    control,
    formState: { errors, isDirty },
  } = useForm<MeModel>();

  const handleCancel = () => {
    if (!initialProfileData) return;
    reset(initialProfileData);
    // const currentValues = getValues();

    // Object.keys(currentValues).forEach((keyStr) => {
    //   const key = keyStr as keyof MeModel;

    //   if (currentValues[key] !== initialProfileData[key]) {
    //     setValue(key, initialProfileData[key], { shouldValidate: false });
    //   }
    // });
  };

  async function getProfileData(): Promise<void> {
    setLoadingInfo(true);

    api
      .get<ServiceResult<MeModel>>("/me")
      .then(({ data: serviceResult }) => {
        const data = serviceResult.result as MeModel;
        setInitialProfileData(data);
        reset(data);
      })
      .catch(apiErrorHandler)
      .finally(() => setLoadingInfo(false));
  }

  const editProfile = (data: ProfilePostArgs) => {
    setLoading(true);

    let args: ProfilePostArgs = {
      fullName: data.fullName,
      nationalDocument: data.nationalDocument,
      addressZipCode: data.addressZipCode,
      addressStreet: data.addressStreet,
      addressNumber: data.addressNumber,
      addressNeighborhood: data.addressNeighborhood,
      addressComplement: data.addressComplement,
    };

    api
      .post<ServiceResult>("/me", args)
      .then(() => {
        toast.success("Dados editados com sucesso!");
        getProfileData();
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  };

  const { FetchCep, fetchingCep } = useViaCep();

  const cep: string = watch("addressZipCode") || "";

  const fetchCep = async (): Promise<void> => {
    if (!cep || somenteNumeros(cep).length !== 8) {
      return;
    }

    const viaCepResponse: ViaCepModel = await FetchCep(cep);

    if (viaCepResponse.erro) {
      toast.error("CEP inválido! Não foi possível obter o endereço.");

      setValue("addressStreet", "");
      setValue("addressComplement", "");
      setValue("addressNeighborhood", "");
    } else {
      setValue("addressZipCode", viaCepResponse.cep);
      setValue("addressStreet", viaCepResponse.logradouro);
      setValue("addressComplement", viaCepResponse.complemento);
      setValue("addressNeighborhood", viaCepResponse.bairro);
    }
  };

  useEffect(() => {
    if (isDirty) {
      fetchCep();
    }
  }, [cep]);

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <MainLayout>
      <h1 className="mb-3">Minha conta</h1>

      <form onSubmit={handleSubmit(editProfile)}>
        <div className="bg-white shadow rounded p-4">
          <>
            <p className="mb-0 bold-20">1. Dados da conta</p>
            <div className="row mb-3 mt-3">
              <div className="col-lg-6 mb-3">
                <label
                  htmlFor="fullName"
                  className="form-label semibold-14 text-secondary"
                >
                  Nome completo
                </label>
                <InputGroup Icon={AiOutlineUser}>
                  <Input
                    control={control}
                    errors={errors}
                    validation={{ required: "Nome é obrigatório" }}
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder={loadingInfo ? "..." : "Nome"}
                    disabled={loadingInfo}
                  />
                </InputGroup>
              </div>

              <div className="col-lg-6 mb-3">
                <label
                  htmlFor="email"
                  className="form-label semibold-14 text-secondary"
                >
                  E-mail
                </label>
                <InputGroup Icon={FaAt}>
                  <Input
                    control={control}
                    errors={errors}
                    validation={{}}
                    type="email"
                    id="email"
                    name="email"
                    placeholder={loadingInfo ? "..." : "Email"}
                    disabled
                  />
                </InputGroup>

                <div className="d-flex align-items-center mt-2"></div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-lg-6 mb-3">
                <label
                  htmlFor="nationalDocument"
                  className="form-label semibold-14 text-secondary"
                >
                  CPF/CNPJ
                </label>
                <InputGroup Icon={AiOutlineFile}>
                  <Input
                    control={control}
                    errors={errors}
                    name="nationalDocument"
                    validation={{}}
                    type="text"
                    id="nationalDocument"
                    placeholder={loadingInfo ? "..." : "CPF/CNPJ"}
                    disabled={loadingInfo}
                  />
                </InputGroup>
              </div>
              <div className="col-lg-6 mb-3">
                <label
                  htmlFor="phone"
                  className="form-label semibold-14 text-secondary"
                >
                  Telefone
                </label>
                <InputGroup Icon={FaPhone}>
                  <Input
                    control={control}
                    errors={errors}
                    name="phone"
                    validation={{ required: "Telefone é obrigatório" }}
                    type="text"
                    id="phone"
                    placeholder={loadingInfo ? "..." : "Telefone"}
                    disabled={loadingInfo}
                  />
                </InputGroup>
              </div>
            </div>

            <p className="mb-0 bold-20">2. Endereço</p>
            <div className="row mb-3 mt-3">
              <div className="col-lg-4 mb-3">
                <label
                  htmlFor="addressZipCode"
                  className="form-label semibold-14 text-secondary"
                >
                  CEP
                </label>
                <InputGroup Icon={GrLocation}>
                  <Input
                    control={control}
                    errors={errors}
                    name="addressZipCode"
                    validation={{}}
                    mask="99999-999"
                    type="text"
                    id="addressZipCode"
                    placeholder={loadingInfo ? "..." : "CEP"}
                    disabled={loadingInfo}
                  />
                </InputGroup>

                {fetchingCep && (
                  <p className="semibold-14 mt-2">Buscando CEP...</p>
                )}
              </div>

              <div className="col-lg-4 mb-3">
                <label
                  htmlFor="addressStreet"
                  className="form-label semibold-14 text-secondary"
                >
                  Logradouro
                </label>
                <InputGroup Icon={FaHome}>
                  <Input
                    control={control}
                    errors={errors}
                    name="addressStreet"
                    validation={{}}
                    type="text"
                    id="addressStreet"
                    placeholder={loadingInfo ? "..." : "Logradouro"}
                    disabled={loadingInfo}
                  />
                </InputGroup>
              </div>
              <div className="col-lg-4 mb-3">
                <label
                  htmlFor="addressNumber"
                  className="form-label semibold-14 text-secondary"
                >
                  Número
                </label>
                <InputGroup Icon={AiOutlineItalic}>
                  <Input
                    control={control}
                    errors={errors}
                    name="addressNumber"
                    validation={{}}
                    type="text"
                    id="addressNumber"
                    placeholder={loadingInfo ? "..." : "Nº"}
                    disabled={loadingInfo}
                  />
                </InputGroup>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-lg-6 mb-3">
                <label
                  htmlFor="addressNeighborhood"
                  className="form-label semibold-14 text-secondary"
                >
                  Bairro
                </label>
                <InputGroup Icon={FaHome}>
                  <Input
                    control={control}
                    errors={errors}
                    name="addressNeighborhood"
                    validation={{}}
                    type="text"
                    id="addressNeighborhood"
                    placeholder={loadingInfo ? "..." : "Bairro"}
                    disabled={loadingInfo}
                  />
                </InputGroup>
              </div>

              <div className="col-lg-6 mb-3">
                <label
                  htmlFor="addressComplement"
                  className="form-label semibold-14 text-secondary"
                >
                  Complemento
                </label>
                <InputGroup Icon={BiPlusCircle}>
                  <Input
                    control={control}
                    errors={errors}
                    name="addressComplement"
                    validation={{}}
                    type="text"
                    id="addressComplement"
                    placeholder={loadingInfo ? "..." : "Complemento"}
                    disabled={loadingInfo}
                  />
                </InputGroup>
              </div>
            </div>

            <button
              className="btn btn-outline-primary px-5 py-2 rounded-pill semibold-16"
              type="button"
              onClick={() => setOpenModal(true)}
            >
              Alterar senha
            </button>
          </>
        </div>

        <div className="d-flex flex-column mt-4 align-items-center">
          <button
            type="submit"
            className="btn btn-success mb-2 px-5 py-2 rounded-pill semibold-16"
            disabled={loading}
          >
            {loading ? "Editando..." : "Editar"}
          </button>
          {isDirty && (
            <span
              className="mt-2 text-secondary text-decoration-underline cursor-pointer"
              onClick={handleCancel}
            >
              Limpar alterações
            </span>
          )}
        </div>
      </form>

      <FormPassword isOpen={openModal} onClose={() => setOpenModal(false)} />
    </MainLayout>
  );
}
