import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MainLayout from "../../components/layouts/main";
import { Loading } from "../../components/loading";
import { TablePagination } from "../../components/table-pagination";
import ListServiceResult from "../../interfaces/list-service-result";
import ContactModel from "../../interfaces/models/contact.model";
import api from "../../services/api-client";
import apiErrorHandler from "../../services/api-error-handler";

export default function Contacts() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("q") || "";
  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("size")) || 10;

  const { handleSubmit, register, reset } = useForm<{ search: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [contacts, setContacts] = useState<ContactModel[]>([]);
  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);

  function handleSearch(data: { search: string }): void {
    setSearchParams((state) => {
      state.set("q", data.search);

      return state;
    });
  }

  async function getContacts(): Promise<void> {
    setLoading(true);

    let url = "/contacts";
    url += `?searchString=${search}`;
    url += `&page=${page}`;
    url += `&pageSize=${pageSize}`;

    api
      .get<ListServiceResult<ContactModel>>(url)
      .then(({ data }) => {
        setContacts(data.result);
        setNumeroPaginas(data.pages);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getContacts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, pageSize]);

  return (
    <MainLayout>
      <div className="mb-4 d-flex flex-column justify-content-center align-items-center flex-md-row justify-content-md-between">
        <h1>Contatos</h1>

        <Link to="/contacts/new" className="btn btn-primary">
          Novo contato
        </Link>
      </div>

      <div className="row justify-content-md-end">
        <div className="col-12 col-md-6 col-lg-4">
          <form
            onSubmit={handleSubmit(handleSearch)}
            className="d-flex flex-column flex-md-row align-items-center mb-4"
          >
            <input
              {...register("search")}
              type="text"
              className="form-control me-2"
              placeholder="Busque um contato pelo nome ou número"
            />

            {search && (
              <button
                type="button"
                className="btn-close mt-2 mt-md-0 me-md-2"
                aria-label="Close"
                onClick={() => {
                  if (search) {
                    setSearchParams((state) => {
                      state.delete("q");
                      return state;
                    });

                    reset();
                  }
                }}
              ></button>
            )}

            <button type="submit" className="btn btn-primary mt-2 mt-md-0">
              Buscar
            </button>
          </form>
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center">
          <Loading color="primary" />
        </div>
      )}

      {!loading && contacts.length === 0 && (
        <div className="alert alert-warning" role="alert">
          Nenhum contato encontrado
        </div>
      )}

      {!loading &&
        contacts.length > 0 &&
        contacts.map((contact) => (
          <div
            key={contact.id}
            className="w-100 d-flex flex-column rounded rounded-4 shadow p-3 mb-3 bg-light text-black border text-decoration-none"
          >
            <div className="row">
              <div className="d-flex flex-column col-12 col-md-4">
                <span>Nome: {contact.name}</span>
                <span>
                  Telefone:
                  {contact.phone?.replace(
                    /(\d{2})(\d{2})(\d{5})(\d{4})/,
                    "$1 ($2) $3-$4",
                  )}
                </span>
              </div>

              <div className="d-flex flex-column col-12 col-md-4">
                <span>
                  Criado em: {moment(contact.createdAt).format("DD/MM/YYYY")}
                </span>
                <span>
                  Atualizado em:{" "}
                  {moment(contact.lastUpdatedAt).format("DD/MM/YYYY")}
                </span>
              </div>

              <div className="d-flex flex-wrap justify-content-center align-items-md-start col-md-4">
                <button
                  type="button"
                  className="d-flex justify-content-center align-items-center btn btn-success me-2 p-2"
                  style={{ width: 50, height: 50 }}
                  title="Editar workspace"
                  onClick={() => {
                    navigate(`/contacts/${contact.id}/edit`);
                  }}
                >
                  <FaPencilAlt className="fs-4" />
                </button>

                {/*
                <button
                  type="button"
                  className="d-flex justify-content-center align-items-center btn btn-danger me-2 p-2"
                  style={{ width: 50, height: 50 }}
                  title="Excluir contato"
                  onClick={() => {
                    toast("Em desenvolvimento", { icon: "🚧" });
                  }}
                >
                  <FaTrashAlt className="fs-4" />
                </button>
                */}
              </div>
            </div>
          </div>
        ))}

      {!loading && contacts.length > 0 && (
        <TablePagination numeroPaginas={numeroPaginas} />
      )}
    </MainLayout>
  );
}
