import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api-client";
import apiErrorHandler from "../../services/api-error-handler";
import Input from "../../components/inputs/input";
import toast from "react-hot-toast";
import { AuthTokenArgs } from "../../interfaces/args/auth-token.args";
import GrantType from "../../enums/grant-type";
import ServiceResult from "../../interfaces/service-result";
import CleanLayout from "../../components/layouts/clean";
import { colors } from "../../style/colors";
import Button from "../../components/button";

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthTokenArgs>({
    defaultValues: {
      grantType: GrantType.Password,
      user: "",
      password: "",
    },
  });

  const handleLogin = async (data: AuthTokenArgs) => {
    setLoading(true);

    api
      .postForm<ServiceResult>("/auth/token", data)
      .then(({ data: { result } }) => {
        sessionStorage.setItem("auth", JSON.stringify(result));
        navigate("/");
        toast.success("Bem vindo!");
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  return (
    <CleanLayout
      renderPosition="left"
      title="Bem-vindo ao IATools"
      description="Estamos felizes em vê-lo(a) de volta! Entre com seus dados de
              acesso abaixo para continuar aproveitando todas as funcionalidades
              do IATools. Se precisar de ajuda, estamos aqui para você."
      image="/images/login-icon.png"
    >
      <h1>Login</h1>
      <form
        className="text-500-darkest-16 w-100 mt-3 mt-md-5 d-flex flex-column justify-content-center"
        onSubmit={handleSubmit(handleLogin)}
      >
        <p className="text-400-black-18 m-0 mb-1">E-mail do usuário</p>
        <div className="form-group mb-4">
          <Input
            control={control}
            errors={errors}
            name="user"
            validation={{ required: "E-mail é obrigatório" }}
            placeholder="example@email.com"
            height="40px"
          />
        </div>
        <div className="form-group mb-4">
          <p className="text-400-black-18 m-0 mb-2">Senha de acesso</p>
          <Input
            control={control}
            errors={errors}
            name="password"
            validation={{ required: "Senha é obrigatória" }}
            placeholder="senha"
            height="40px"
            type="password"
          />
        </div>

        <div className="mt-4">
          <Button
            type="submit"
            text={loading ? "Entrando..." : "Entrar"}
            color={colors.gradient.secondary}
            disabled={loading}
          />
        </div>

        <div className="mt-4 d-flex flex-column align-items-center">
          <Link
            to="/forgot-password"
            className="text-500-darkest-16"
            style={{ color: colors.secondary }}
          >
            Esqueci minha senha
          </Link>
          <div className="mt-2">
            <span>Não tem uma conta?</span>
            <Link
              to="/register"
              className="text-500-darkest-16 ms-2"
              style={{ color: colors.secondary }}
            >
              Criar conta
            </Link>
          </div>
        </div>
      </form>
    </CleanLayout>
  );
}
