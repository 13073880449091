import clsx from "clsx";
import { ComponentProps } from "react";
import { colors } from "../../style/colors";
import "./button.css";

interface Props extends Omit<ComponentProps<"button">, "ref"> {
  disabled?: boolean;
  width?: string;
  text: string;
  color?: string;
}
export default function Button({
  disabled = false,
  width,
  text,
  color = colors.primary,
  type = "button",
  ...rest
}: Props) {
  return (
    <button
      {...rest}
      className={clsx("custom-btn", rest.className)}
      disabled={disabled ? true : false}
      style={{
        ...rest.style,
        maxWidth: width,
        background: color,
      }}
    >
      {text}
    </button>
  );
}
